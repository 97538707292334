@import 'src/styles/variables';

.ui_kit_common {
  font-size: var(--font-s);
  font-weight: 100;

  display: inline-block;
  width: 100%;
  height: 42px;
  padding: 0 var(--space-l);
  margin: 0;

  line-height: 42px;
  text-align: left;
  box-sizing: border-box;
  vertical-align: top;
  outline-color: transparent;

  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border);
  color: var(--color-black);
  background-color: var(--color-white);

  &:focus {
    background-color: #FEF6EB;
  }
}

.ui_kit_dialog {
  position: absolute;
  bottom: 48px;
  left: 0;

  display: block;
  width: 300px;
  max-height: 200px;
  padding: 8px 0;

  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px #C0C0C0;
}
