@import 'src/styles/variables';

.body_wrapper {
  display: block;
  min-height: 100vh;
  padding: var(--space-xxl);

  box-sizing: border-box;
  text-align: left;

  background-color: #F5F7F9;
}
