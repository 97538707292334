@import 'src/styles/variables';

.ui_kit_switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0;
  margin: 0;

  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  background-color: #E2E9F0;

  &_item {
    display: inline-block;
    padding: 8px 0;
    margin: 0;
    height: 100%;
    width: 100%;

    font-size: var(--font-s);
    font-weight: 100;

    cursor: pointer;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;

    border: 1px solid #E2E9F0;
    border-right: none;
    border-radius: 0;
    background-color: #FFFFFF;
    color: #84858D;

    &_selected {
      color: #FFFFFF;
      border-top: 1px solid var(--color-button);
      border-bottom: 1px solid var(--color-button);
      background-color: var(--color-button);
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-right: 1px solid #E2E9F0;
      border-radius: 0 8px 8px 0;
    }
  }
}

@media (max-width: 1000px) {
  .switch {
  }
}
