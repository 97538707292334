:root {
  --font-xxs: 10px;
  --font-xs: 13px;
  --font-s: 13px;
  --font-m: 16px;
  --font-l: 18px;

  --space-xxxs: 2px;
  --space-xxs: 4px;
  --space-xs: 6px;
  --space-s: 8px;
  --space-sm: 10px;
  --space-m: 12px;
  --space-l: 16px;
  --space-xl: 18px;
  --space-xxl: 24px;

  --border-radius-s: 4px;
  --border-radius-m: 8px;
  --border-radius-l: 12px;


  --color-first: #4162B5;
  --color-second: #ED675F;

  --color-black: #12131B;
  --color-grey: #CBCBCD;
  --color-white: #FFFFFF;
  --color-border: #E2E9F0;
  --color-button: #1A73E8;
  --color-button-2: #3081EA;
  --color-button-3: #0B59CC;

  --color-11: #7F9BE0;
  --color-12: #E9A5A1;
  --color-13: #C2ECC1;
  --color-14: #B6BAE9;
  --color-15: #A18BE0;

  --color-21: #FFC178;
  --color-22: #75CCE0;
  --color-23: #B2E069;
  --color-24: #EC95B6;
  --color-25: #E2DA84;

  --color-31: #A7C0FF;
  --color-32: #D7D4B1;
  --color-33: #E0BA96;
  --color-34: #E0BE69;
  --color-35: #C0D79C;

  --color-41: #B6C5C9;
  --color-42: #9E98AD;
  --color-43: #AACFD8;
  --color-44: #BAB2D0;
  --color-45: #DCBAC7;
}
