@import 'src/styles/variables';

.loading {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  min-height: 80px;
  text-align: center;
}
