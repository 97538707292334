.modal_window,
.modal_window_fullscreen {
  display: block;
  width: 400px;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: 0 0 5px gray;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.modal_window_fullscreen {
  position: relative;
  height: 100vh;
  width: 100vh;
  border-radius: 0;
  animation: modal_window_fullscreen 0.2s ease-out forwards;
}

@keyframes modal_window_fullscreen {
  from {
    right: 100%;
  }
  to {
    right: 0;
  }
}

.modal_window {
  &_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    background-color: rgba(90, 90, 90, 0.2);
  }

  &_title,
  &_body,
  &_footer {
    display: block;
    overflow: hidden;
    margin: 0 auto;
    box-sizing: border-box;

    border-radius: 8px;

    text-align: left;
    white-space: normal;
  }

  &_title {
    position: relative;
    padding: 24px;
  }

  &_body {
    max-height: 60vh;
    padding: 0 24px;
    overflow: auto;
  }

  &_footer {
    padding: 24px;
    text-align: right;
  }

  &_close {
    position: absolute;
    top: 12px;
    right: 12px;

    display: block;
    width: 32px;
    height: 32px;

    cursor: pointer;
    transition: transform 1s ease-in-out;

    &:hover {
      transform: rotate(180deg);
    }
  }
}
