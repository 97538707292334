@import 'src/styles/variables';

.sort_up,
.sort_down {
  display: inline-block;
  height: 0;
  width: 0;
  margin: 0 0 -5px 8px;

  cursor: pointer;
  transform: rotateZ(-45deg);

  border: 6px solid var(--color-grey);
  border-left-color: white;
  border-bottom-color: white;
}

.sort_down {
  margin: 0 0 3px 8px;
  transform: rotateZ(135deg);
}
