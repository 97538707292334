@import 'src/styles/variables';

.nothing_found {
  display: block;
  margin: 0 auto;
  padding: 24px 0;
  max-width: 80%;

  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &_icon {
    display: block;
    width: 200px;
    height: 100px;
    margin: 0 auto 24px auto;
  }

  &_title,
  &_text ,
  &_console {
    font-weight: 100;
    font-size: var(--font-xs);
    font-family: Arial, Verdana, sans-serif;

    display: block;
    max-width: 400px;
    padding: var(--space-s);

    box-sizing: border-box;
    text-align: left;
    line-height: var(--font-m);
    text-decoration: none;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #73809F;
  }

  &_title {
    font-weight: bold;
    margin: 0 auto 4px auto;
    text-align: center;
  }

  &_link {
    text-decoration: underline;
    color: var(--color-button);
    &:hover {
      text-decoration: none;
    }
  }

  &_console {
    padding: var(--space-s);
    line-height: var(--font-l);
    border-radius: var(--border-radius-s);
    border: 1px solid var(--color-border);
    background-color: var(--color-border);
  }
}
