html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  font-size: 100%;
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  outline: 0;
  border: 0;
  background: transparent
}

body {
  line-height: 1
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block
}

ul {
  list-style: none
}

blockquote, q {
  quotes: none
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none
}

a {
  font-size: 100%;
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  background: transparent
}

ins {
  color: #000000;
  background-color: #FFFF99;
  text-decoration: none
}

mark {
  font-weight: 700;
  font-style: italic;
  color: #000000;
  background-color: #FFFF99
}

del {
  text-decoration: line-through
}

table {
  border-spacing: 0;
  border-collapse: collapse
}

hr {
  display: block;
  height: 1px;
  padding: 0;
  margin: 1em 0;
  border: 0
}

input, select, td {
  padding: 0;
  margin: 0;
  vertical-align: middle
}

td {
  font-size: 100%;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0
}
