@import 'src/styles/variables';

.table_wrapper {
  display: block;
}

.table {
  display: block;
  width: fit-content;
  break-inside: auto;
  --table-cell-height: 48px;
  --table-bar-width: 350px;
}

.table_tree {
  --table-cell-height: 22px;
  --table-bar-width: 200px;
}

.table_row {
  position: relative;
  font-weight: 100;
  display: block;
  white-space: nowrap;
  border-bottom: 1px solid #EEEEEE;
  break-inside: auto;
}

.table_row:last-child {
  border-bottom: none;
}

.table_row_hide {
  opacity: 0.2;
}

.table_cell,
.table_header_cell {
  font-size: var(--font-xs);
  z-index: 0;

  display: inline-block;
  height: var(--table-cell-height);
  line-height: var(--table-cell-height);

  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.table_cell {
  padding: 0 4px;
}

.table_header_cell {
  font-weight: bold;
  height: var(--table-cell-height);
  padding: 0 4px;
  line-height: var(--table-cell-height);
  background-color: #FFFFFF;
}

.table_cell:first-child,
.table_header_cell:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.table_cell:first-child {
  background-color: rgba(255, 255, 255, 0.9);
}

.table_cell_number {
  text-align: right;
}

.disabled {
  opacity: 0.4;
  filter: grayscale(0.6);
}

@media (max-width: 800px) {
  .table_cell:first-child,
  .table_header_cell:first-child {
    position: static;
  }
}

@media print {
  .table_cell:first-child {
    background-color: transparent;
  }
}
