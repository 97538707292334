@import 'src/styles/variables';

.paginator {
  display: block;
  margin: 0 auto 24px auto;
  white-space: normal;
  box-sizing: border-box;
  vertical-align: top;
}

.paginator_text {
  font-weight: 100;
  font-size: var(--font-xs);
  font-family: Arial, Verdana, sans-serif;

  display: inline-block;
  height: 42px;
  padding: 0;
  margin: 0 var(--space-s);

  text-align: left;
  line-height: 42px;
  text-decoration: none;
  vertical-align: bottom;
  color: var(--color-black);
}

.paginator_page_number {
  display: inline-block;
}

input.paginator_page_number {
  display: inline-block;
  width: 42px;
  padding: 0;
  margin: 0 var(--space-s);
  text-align: center;
}
